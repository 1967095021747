<template>
    <v-container>
        <section id="login-page">
            <div class="container my-5">
                <div class="row justify-content-center">
                <div class="col-md-6">
                    <div class="card shadow p-3 bg-body rounded">
                    <div class="card-header mb-3 px-4">
                        <h3 class="card-title my-1 text-center">{{ $t("message.forgot-password") }}</h3>
                    </div>
                    <div class="card-body p-3 m-1">
                        <form class="px-5" method="POST" @submit.prevent="submitEmail">
                            <v-row>
                                <div>
                                    <label>
                                    {{ $t("message.label-email") }}
                                    </label>
                                </div>
                                <v-col cols="12" xs="2" class="input-class">
                                    <v-text-field
                                        type="email"
                                        outlined
                                        v-model="tempForgotPass.email"
                                        :error-messages="emailErrors"
                                        @input="$v.tempForgotPass.email.$touch()"
                                        @blur="$v.tempForgotPass.email.$touch()"
                                        required
                                    />
                                </v-col>
                            </v-row>
                        <div class="d-grid  mt-4">
                            <v-btn type="submit" depressed color="primary" @submit.prevent="submitEmail">
                            {{ $t("message.submit-button") }}
                            </v-btn>
                        </div>
                        <div class="d-flex flex-row justify-center mt-4">
                            <v-btn @click="$router.push('/login')" text color="primary">
                                {{ $t("message.back-button") }}
                            </v-btn>
                        </div>
                        </form>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </section>
    </v-container>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
export default {
    name: "ForgotPassword",
    mixins: [validationMixin],
    validations: {
        tempForgotPass: {
            email: { required, email }
        }
    },
    data() {
        return {
            tempForgotPass: {
                email: ""
            }
        }
    },
    methods: {
        async submitEmail() {
            this.$v.$touch();
            if(!this.tempForgotPass.email) {
                Swal.fire(this.$t("message.err-msg-email-required"));
                return false;
            } else {
                let payload = {
                    email: this.tempForgotPass.email,
                    url_redirect: window.location.origin + process.env.BASE_URL + "resetpassword",
                }
                await this.$store.dispatch("auth_module/cekEmail", payload)
            }
        }
    },
    computed: {
        emailErrors() {
            let errors = [];
            if (!this.$v.tempForgotPass.email.$dirty) return errors;
            // !this.$v.email.email && errors.push("Must be valid e-mail");
            !this.$v.tempForgotPass.email.email && errors.push(this.$t("message.err-msg-email"));
            !this.$v.tempForgotPass.email.required &&
                errors.push(this.$t("message.err-msg-email-required"));
            this.err = errors;
            return errors;
        },
    }
}
</script>

<style>

</style>